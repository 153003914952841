<template>
    <div class="Content-01">
        <Content01 />
        <MobileContent01 />
    </div>
</template>

<script>
  import Content01 from '@/components/Galerie/Galerie_Content_01.vue'
  import MobileContent01 from '@/components/Galerie/mobile/Mobile_Galerie_Content_01.vue'

  export default {
    name: 'Galerie',
    components: { Content01, MobileContent01 }
  }
</script>