<template>
    <div class="addEvent">
        <v-card v-if="loggedIn && this.role.includes('ROLE_ADMIN')" class="ma-3">
            <v-card-title class="red darken-4 white--text text-h4">
                Benutzer hinzufügen:
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
                <v-text-field
                    v-model="username"
                    label="Username"
                    required
                    :error-messages="usernameErrors"
                    :counter="100"
                    @input="$v.username.$touch()"
                    @blur="$v.username.$touch()"
                ></v-text-field>
                <v-text-field
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    v-model="password"
                    label="Passwort"
                    required
                    :error-messages="passwordErrors"
                    :counter="100"
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                    @click:append="show = !show"
                ></v-text-field>
                <v-select 
                    :items="existingRoles"
                    v-model="roles"
                    label="Rolle"
                    multiple
                    :error-messages="rolesErrors"
                    @input="$v.roles.$touch()"
                    @blur="$v.roles.$touch()"  
                >

                </v-select>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn @click="clear">
                clear
                </v-btn>

                <v-spacer></v-spacer>
                
                <v-btn @click="submit" class="mr-4" color="success">
                Benutzer hinzufügen
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="loggedIn && !this.role.includes('ROLE_ADMIN')" class="ma-3">
            <v-card-title class="red darken-4 white--text text-h4">
                Nicht Berechtigt!
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
                Du hast nicht die nötigen Berechtigungen!
            </v-card-text>
        </v-card>

        <v-card v-if="!loggedIn" class="ma-3">
            <v-card-title class="red darken-4 white--text text-h4">
                You are not logged in!
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
                Bitte einloggen um einen neuen Benutzer hinzuzufügen!
            </v-card-text>
        </v-card>

        <!--Snackbar popup-->
        <v-snackbar 
            v-model="snackbar" 
            :timeout="timeout" 
            multi-line
            right 
            top
            outlined
            light
            text
            :color=errorType
        >
            {{ErrorMessage}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color=errorType
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>       
        </v-snackbar>
    </div>
</template>

<script>
    //import Vue from 'vue'
    //import axios from 'axios'
    import { authPrefix, url} from '@/variables'
    import { validationMixin } from 'vuelidate'
    import { required, maxLength, minLength } from 'vuelidate/lib/validators'
    import authHeader from '@/functions/index.js'

    export default {
        mixins: [validationMixin],

        validations: {
            username: {required, maxLength: maxLength(100)},
            password: {required, maxLength: maxLength(100), minLength: minLength(6)},
            roles: {required},
        },

        computed: {
            usernameErrors () {
                const errors = []
                if (!this.$v.username.$dirty) return errors
                !this.$v.username.required && errors.push('Name is required!')
                !this.$v.username.maxLength && errors.push('Username is too long!')
                return errors
            },
            passwordErrors () {
                const errors = []
                if (!this.$v.password.$dirty) return errors
                !this.$v.password.required && errors.push('Password is required!')
                !this.$v.password.minLength && errors.push('Password is too short!')
                !this.$v.password.maxLength && errors.push('Password is too long!')
                return errors
            },
            rolesErrors () {
                const errors = []
                if (!this.$v.roles.$dirty) return errors
                !this.$v.roles.required && errors.push('Role is required!')
                return errors
            },
        },
        data() {
            return{
                //Adding Items
                username: "",
                password: "",
                roles: "",

                loggedIn: false,
                //Snackbar
                snackbar: false,
                ErrorMessage: "",
                errorType: "info",
                timeout: 2000,
                //Tabs
                tab: null,
                //Roles
                existingRoles: [],

                show: false,

                role: [],
            }
        },
        methods: {
            async getRoles() {
                if(localStorage["user"] != null && this.role.includes('ROLE_ADMIN')) {
                    await this.axios.get(url + authPrefix + "/roles", {headers: authHeader()}).then((response) => {
                        this.existingRoles = response.data
                    })
                }
            },
            
            checkIfLoggedIn() {
                if(localStorage['user']) {
                    this.loggedIn = true
                    let user = JSON.parse(localStorage.getItem('user'));
                    this.role = user.roles
                }
                else {
                    this.loggedIn = false
                    this.role = []
                }
            },

            addUser() {
                // POST request using axios with set headers
                if (this.username && this.password && this.roles != null) {
                    const event = { username: this.username, password: this.password, roles: this.roles, };
                    this.axios.post(url + authPrefix + "/signup", event, {headers: authHeader()})
                    .catch((err) => {
                        this.ErrorMessage = err.response.data.message
                        this.errorType = "error"
                        this.snackbar = true;
                        return
                    });
                    this.ErrorMessage = this.username + " has been created!"
                    this.errorType = "success"
                    this.snackbar = true;
                }       
            },

            submit () {
                this.$v.username.$touch()
                this.$v.password.$touch()
                this.$v.roles.$touch()
                if (this.$v.username.$invalid || this.$v.password.$invalid || this.$v.roles.$invalid) {
                    this.submitStatus = 'Error'
                }
                else {
                    this.addUser()
                    this.clear()
                }     
            },
            clear () {
                this.$v.$reset()
                this.username = ''
                this.password = ''
                this.roles = ''
            },
        },
        created() {
            this.interval = setInterval(() => this.checkIfLoggedIn(), 1000);
            this.interval = setInterval(() => this.getRoles(), 1000);
        },
    }
</script>