<template>
    <div class="content">
        <div class="galerie d-flex d-sm-none" no-gutters><!--Removes the Spacing between the different row items-->
            <v-card class="ma-3" width=100%>
                <v-card-title class="red darken-4 white--text text-h4">Galerie</v-card-title>

                <v-row 
                    class="pa-2 d-flex child-flex"
                    v-for="(image,i) in albums" 
                    :key="i" 
                >
                    <v-col>
                        <v-card>
                            <a :href="image.link" target="_blank" style="text-decoration: none;">
                                <v-img aspect-ratio="1.5" :src="image.src">
                                    <v-container fill-height fluid
                                        class="d-flex v-card--reveal text-h2 white--text"
                                        style="height: 100%;"
                                    >
                                        <v-row
                                            align="center"
                                            justify="center"
                                            class="text-h5 text-center grey darken-4"                                              
                                        >
                                            {{image.desc}}                                            
                                        </v-row>
                                    </v-container>
                                </v-img>             
                            </a>            
                        </v-card>                   
                    </v-col>
                </v-row>

            </v-card>
        </div>
    </div>
</template>

<script>
    import {url, eventPrefix} from '@/variables/index.js'

    export default {
    name: 'Home',
    components: { },
    data() {
            return {
                 albums: [],
            }
        },
        methods: {
           async getAlbums() {
                const headers = { 
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json"
                };
                await this.axios.get(url + eventPrefix + "/albums", { headers }).then((response) => {
                    this.albums = response.data
                })
                .catch((err) => {
                    this.ErrorMessage = err.response.data.message
                    this.snackbar = true;
                })
                this.albums.sort(function (a, b) {
                    return b.date.localeCompare(a.date)
                });
            }, 
        },
        beforeMount() {
            this.getAlbums()
            this.interval = setInterval(() => this.getAlbums(), 10000)        
        }
    }
</script>