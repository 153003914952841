<template>
    <div class="addEvent">
        <section v-if="loggedIn && this.role.includes('ROLE_ADMIN')">
            <v-card class="">
                <v-card-title>App-Bar 1</v-card-title>
                <v-card-content>
                    <v-card 
                        class="overflow-hidden rounded-lg rounded-t-0" 
                        v-scroll:#scroll-target="onScroll"
                    >
                        <v-app-bar
                            absolute
                            color="white"
                            shrink-on-scroll
                            prominent
                            src="@/pictures/grouppicture.jpg"
                            fade-img-on-scroll
                            scroll-target="#scrollContainer"
                            scroll-threshold="500"
                        >

                            <v-spacer></v-spacer>

                            <span id="app-bar-content" fluid class="rounded-lg rounded-t-0" style="background-color: rgba(0,0,0,0.6); color: white;">
                                <v-btn class="pl-1 pr-1">Bürgerkapelle Bad Ischl</v-btn>
                                <v-btn class="pl-1 pr-1">Galerie</v-btn>
                                <v-btn class="pl-1 pr-1">Chronik</v-btn>
                                <v-btn class="pl-1 pr-1">Impressum</v-btn>
                            </span>

                            <v-spacer></v-spacer>

                        </v-app-bar>
                        <v-sheet
                            id="scrollContainer"
                            class="overflow-y-auto black--text"
                            max-height="600"
                            v-on:scroll="this.onScroll"
                        >
                            <v-container style="height: 1500px;"></v-container>
                        </v-sheet>
                    </v-card>    
                </v-card-content>          
            </v-card>
        </section>

        <section v-if="loggedIn && !this.role.includes('ROLE_ADMIN')" >
            <v-card class="ma-3">
                <v-card-title class="red darken-4 white--text text-h4">
                   Nicht Berechtigt!
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    Du hast nicht die nötigen Berechtigungen!
                </v-card-text>
            </v-card>
        </section>

        <section v-if="!loggedIn" >
            <v-card class="ma-3">
                <v-card-title class="red darken-4 white--text text-h4">
                    You are not logged in!
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    Please login in order to register a new user!
                </v-card-text>
            </v-card>
        </section>

        <v-snackbar 
            v-model="snackbar" 
            :timeout="timeout" 
            multi-line
            right 
            top
            outlined
            light
            text
            :color=errorType
        >
            {{ErrorMessage}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color=errorType
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>       
        </v-snackbar>
    </div>
</template>

<style scoped>
    /deep/ .v-toolbar__content {
        padding: 0px !important;
    }
</style>

<script>
    import authHeader from '@/functions/index.js'
    import { authPrefix, eventPrefix, url} from '@/variables'

    export default {
        computed: {

        },
        data() {
            return{
                loggedIn: false,
                //Snackbar
                snackbar: false,
                ErrorMessage: "",
                errorType: "info",
                timeout: 2000,

                //Lists
                search: "",
                //userList
                userList: [],
                userHeaders: [
                    {text: 'Username', value: 'username', class: 'title font-weight-bold'},
                    {text: 'Rollen', value: 'roles', class: 'title font-weight-bold'},
                    {text: 'Actions', value: "actions", align: 'center', class: 'title font-weight-bold'},
                ],

                //contactList
                contactList: [],
                contactHeaders: [
                    {text: 'Username', value: 'username', class: 'title font-weight-bold'},
                    {text: 'Rollen', value: 'roles', class: 'title font-weight-bold'},
                    {text: 'Actions', value: "actions", align: 'center', class: 'title font-weight-bold'},
                ],

                //Snackbar Info
                user: [],
                contact: [], 
                role: [],

                //Error Handling
                deleteUserErr: false,
                deleteContactErr: false,

                //LocalStorage
                localStorageUser: [],

                //Scroll Method
                offsetTop: 0,
                maxOpacity: 0.7,
                scrollHeight: 150,
                opacity: 0.7,
            }
        },
        methods: {
            
            onScroll() {
                this.offsetTop = document.getElementById("scrollContainer").scrollTop
            },

            checkIfLoggedIn() {
                if(localStorage['user']) {
                    this.loggedIn = true
                    let user = JSON.parse(localStorage.getItem('user'));
                    this.localStorageUser = user
                    this.role = user.roles
                }
                else {
                    this.loggedIn = false
                    this.userList = []
                    this.role = []
                }
            },

//Contacs

            async getContacts() {
                if(localStorage["user"] != null && this.role.includes('ROLE_ADMIN')) {
                    await this.axios.get(url + eventPrefix + "/users", {headers: authHeader()}).then((response) => {
                        this.contactList = response.data
                    })
                }
            },

            async deleteContact(id) {
                // POST request using axios with set headers
                if(window.confirm("Der Kontakt wird unwiederruflich gelöscht! Sind Sie sicher?"))
                {
                    const contact = { id: id};
                    await this.axios.post(url + authPrefix +"/deleteContact", contact, { headers: authHeader() })
                    .catch((err) => {
                        this.ErrorMessage = err.response.data.message
                        this.errorType = "error"
                        this.snackbar = true;
                        this.deleteContactErr = true
                    });
                    if(this.deleteContactErr != true) {
                        this.ErrorMessage = "Kontakt " + this.user.toString() + " entfernt!"
                        this.errorType = "success"
                        this.snackbar = true
                        this.getContacts()
                        this.deleteContactErr = false
                    }
                     
                }
            },

//Users

            async getUsers() {
                if(localStorage["user"] != null && this.role.includes('ROLE_ADMIN')) {
                    await this.axios.get(url + authPrefix + "/users", {headers: authHeader()}).then((response) => {
                        this.userList = response.data
                    })
                }
            },

            async deleteUser(id) {
                // POST request using axios with set headers
                if(this.localStorageUser.id != id) {
                    if(window.confirm("Der Benutzer wird unwiederruflich gelöscht! Sind Sie sicher?"))
                    {
                        const user = { id: id};
                        await this.axios.post(url + authPrefix +"/deleteUser", user, { headers: authHeader() })
                        .catch((err) => {
                            this.ErrorMessage = err.response.data.message
                            this.errorType = "error"
                            this.snackbar = true;
                            this.deleteUserErr = true
                        })
                        if(this.deleteUserErr != true) {
                            this.ErrorMessage = "User " + this.user.toString() + " gelöscht!"
                            this.errorType = "success"
                            this.snackbar = true
                            this.getUsers() 
                            this.deleteUserErr = false
                        }
                                        
                    }
                } else {
                    this.ErrorMessage = "Fehler: Du bist mit diesem Benutzer eingelogged!"
                    this.errorType = "info"
                    this.snackbar = true
                }
            },
        },
        created() {
            this.interval = setInterval(() => this.checkIfLoggedIn(), 1000);
            this.interval = setInterval(() => this.getUsers(), 1000);
        },

        beforeMount() {
               
        },
        destroyed () {
            this.interval = ""
        },
    }
</script>