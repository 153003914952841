<template>
    <div class="content">
        <div class="galerie d-none d-sm-inline" no-gutters><!--Removes the Spacing between the different row items-->
            <!--Admin-->
            <v-card v-if="loggedIn" class="ma-3">
                <v-card-title class="red darken-4 white--text text-h4">Galerie bearbeiten</v-card-title>

                <v-divider></v-divider>

                <v-data-table
                    :search="search"
                    :headers="albumHeaders"
                    :items="albums"
                    :footer-props=footerProps
                    :options=dataTableOptions
                    sort-by="date"
                    :sort-desc="true"
                >
                    <template v-slot:[`item.date`]="{ item }">
                        <span>{{ new Date(item.date).toLocaleDateString('de-DE') }}</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn small color="warning" dark @click="clear(), Id = item._id; album = item.desc; date = item.date; src = item.src; link = item.link; editDialog ='true'"><v-icon small>mdi-pencil</v-icon></v-btn>       
                        <v-btn small color="error" @click="deleteAlbum(item._id);"><v-icon small>mdi-delete</v-icon></v-btn>
                    </template>  
                </v-data-table>
            </v-card>
            
            <!--Flickr-->
            <v-card class="ma-3">
                <v-card-title class="red darken-4 white--text text-h4">Galerie</v-card-title>

                <v-row class="pa-2">
                    <v-col
                        v-for="(image,i) in albums" 
                        :key="i" 
                        class="d-flex child-flex"
                        cols="4"
                    >
                        <v-hover
                            v-slot="{ hover }"
                        >
                            <v-card
                                class="hover_card"
                                :elevation="hover ? 12 : 2"
                                :class="{ 'on-hover': hover }"  
                            >
                                <a :href="image.link" target="_blank" style="text-decoration: none;">
                                    <v-img aspect-ratio="1.5" :src="image.src">
                                        <v-fade-transition>
                                            <v-container fill-height fluid
                                                v-if="hover"
                                                class="d-flex v-card--reveal text-h2 white--text"
                                                style="height: 100%;"
                                            >
                                                <v-row
                                                    align="center"
                                                    justify="center"
                                                    class="text-h5 text-center grey darken-4"                                              
                                                >
                                                    {{image.desc}}                                            
                                                </v-row>
                                            </v-container>
                                        </v-fade-transition>
                                    </v-img>             
                                </a> 
                            </v-card>                   
                        </v-hover>
                    </v-col>
                </v-row>
            </v-card>
        </div>

        <!--Edit Dialog-->
      <template>
        <!--@click="editItem(item._id, item.item, item.itemType);" -->
        <v-dialog v-model="editDialog">
            <v-card>
                <v-card-title class="red darken-4 white--text text-h6">
                    Album hinzufügen:

                    <v-spacer></v-spacer>

                    <v-btn dark class="" @click="editDialog=!editDialog" large icon><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    <v-row class="ma-1">
                        <v-col>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="albumName"
                                        label="Album"
                                        required
                                        :error-messages="albumNameErrors"
                                        :counter="100"
                                        @input="$v.albumName.$touch()"
                                        @blur="$v.albumName.$touch()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn class="ma-3 pa-1" @click="pinOnOff_Name = !pinOnOff_Name; keepOld(1)" large icon><v-icon>{{ !pinOnOff_Name ? 'mdi-pin' : 'mdi-pin-off'}}</v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="albumSrc"
                                        label="Bild"
                                        required
                                        :error-messages="albumSrcErrors"
                                        :counter="100"
                                        @input="$v.albumSrc.$touch()"
                                        @blur="$v.albumSrc.$touch()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn class="ma-3 pa-1" @click="pinOnOff_Src = !pinOnOff_Src; keepOld(2)" large icon><v-icon>{{ !pinOnOff_Src ? 'mdi-pin' : 'mdi-pin-off'}}</v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="albumLink"
                                        label="Link"
                                        required
                                        :error-messages="albumLinkErrors"
                                        :counter="100"
                                        @input="$v.albumLink.$touch()"
                                        @blur="$v.albumLink.$touch()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn class="ma-3 pa-1" @click="pinOnOff_Href = !pinOnOff_Href; keepOld(3)" large icon><v-icon>{{ !pinOnOff_Href ? 'mdi-pin' : 'mdi-pin-off'}}</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4" align="center">
                            Aktuelles Datum: {{new Date(this.date).toLocaleDateString("de-DE")}}<br>
                            <v-date-picker
                                color="grey darken-2"
                                :first-day-of-week="1"
                                locale="de"
                                v-model="albumDate"
                                required
                                :error-messages="albumDateErrors"
                                @input="$v.albumDate.$touch()"
                                @blur="$v.albumDate.$touch()"
                            ></v-date-picker>
                            <v-text-field
                                :error-messages="albumDateErrors"
                                @input="$v.albumDate.$touch()"
                                @blur="$v.albumDate.$touch()"
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn @click="clear">
                    clear
                    </v-btn>

                    <v-spacer></v-spacer>
                    
                    <v-btn @click="submit()" class="mr-4" color="warning">
                    Edit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
      </template>

      <!--Snackbar popup-->
      <v-snackbar 
            v-model="snackbar" 
            :timeout="timeout" 
            multi-line
            right 
            top
            outlined
            light
            text
            :color=errorType
        >
            {{ErrorMessage}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color=errorType
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>       
        </v-snackbar>
    </div>
</template>

<style scoped>
    .hover_card {
        transition: opacity .2s ease-in-out;
    }

    .hover_card:not(.on-hover) {
        opacity: 0-9;
    }
</style>

<script>
    import {url, eventPrefix} from '@/variables/index.js'
    import authHeader from '@/functions/index.js'

    import { validationMixin } from 'vuelidate'
    import {required, maxLength } from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],

        validations: {
            albumName: {required, maxLength: maxLength(100)},
            albumDate: {required},
            albumSrc:  {required}, 
            albumLink:  {required}, 
        },

        name: 'Home',
        components: { },
        computed: {
            albumNameErrors () {
                const errors = []
                if (!this.$v.albumName.$dirty) return errors
                !this.$v.albumName.required && errors.push('Name is required!')
                return errors
            },
            albumDateErrors () {
                const errors = []
                if (!this.$v.albumDate.$dirty) return errors
                !this.$v.albumDate.required && errors.push('Date is required!')
                return errors
            },
            albumSrcErrors () {
                const errors = []
                if (!this.$v.albumSrc.$dirty) return errors
                !this.$v.albumSrc.required && errors.push('A link to an image is required!')
                return errors
            },
            albumLinkErrors () {
                const errors = []
                if (!this.$v.albumLink.$dirty) return errors
                !this.$v.albumLink.required && errors.push('A link is required!')
                return errors
            },
        },
        data() {
            return {
                //Flickr    
                albums: [],

                //Datatable
                dataTableOptions: {
                    itemsPerPage: 5,
                    page: 1,
                },

                footerProps: {
                    'items-per-page-options':[5],
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus'
                },

                albumHeaders: [
                    {text: 'Album', value: 'desc', class: 'title font-weight-bold'},
                    {text: 'Datum', value: 'date', class: 'title font-weight-bold'},
                    {text: 'Image', value: 'src', class: 'title font-weight-bold'},
                    {text: 'Link', value: 'link', class: 'title font-weight-bold'},
                    {text: 'Actions', value: 'actions', class: 'title font-weight-bold'},
                ],

                search: "",

                //editAlbum
                albumName: "",
                albumDate: "",
                albumSrc: "",
                albumLink: "",

                album: "",
                date: "",
                src: "",
                link: "",

                //Login
                loggedIn: false,
                editDialog: false,

                //Pin Icon On-Off
                pinOnOff_Name: false,
                pinOnOff_Src: false,
                pinOnOff_Href: false,

                //Snackbar
                snackbar: false,
                ErrorMessage: "",
                errorType: "info",
                timeout: 2000,
            }
        },
        methods: {
            keepOld(_id) {
                if(_id == 1){
                    if(this.albumName != this.album) {
                        this.albumName = this.album
                    } else {this.albumName = ""}
                }
                else if (_id == 2) {
                    if(this.albumSrc != this.src) {
                        this.albumSrc = this.src
                    } else {this.albumSrc = ""}
                }
                else if (_id == 3) {
                    if(this.albumLink != this.link) {
                        this.albumLink = this.link
                    } else {this.albumLink = ""}

                }
            },

            submit () {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.submitStatus = 'Error'
                }
                else {
                    this.editAlbum()
                    this.clear()
                    this.editDialog = !this.editDialog 
                }    
            },

            async getAlbums() {
                const headers = { 
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json"
                };
                await this.axios.get(url + eventPrefix + "/albums", { headers }).then((response) => {
                    this.albums = response.data
                })
                this.albums.sort(function (a, b) {
                    return b.date.localeCompare(a.date)
                });
            },

            async deleteAlbum(id) {
                // POST request using axios with set headers
                const event = { id: id };
                await this.axios.post(url + eventPrefix +"/deleteAlbum", event, { headers: authHeader() })
                .catch((err) => {
                    this.ErrorMessage = err.response.data.message
                    this.errorType = "error"
                    this.snackbar = true;
                })
                this.ErrorMessage = "Album gelöscht!"
                this.errorType = "success"
                this.snackbar = true
                this.getAlbums() 
            },

            async editAlbum() {
                if (this.albumName && this.albumDate && this.albumSrc && this.albumLink != null) {
                    // POST request using axios with set headers
                    const event = { id: this.Id, desc: this.albumName, date: this.albumDate, src: this.albumSrc, link: this.albumLink, };
                    await this.axios.post(url + eventPrefix + "/editAlbum", event, { headers: authHeader() })
                    .catch((err) => {
                        this.ErrorMessage = err.response.data.message
                        this.errorType = "error"
                        this.snackbar = true;
                    })
                    this.editDialog = false;
                    this.ErrorMessage = "Album bearbeitet!"
                    this.errorType = "success"
                    this.snackbar = true; 
                }
                else {
                    this.ErrorMessage = "One or more parameters were NULL!"
                    this.errorType = "info"
                    this.snackbar = true;
                }
                this.getAlbums()      
            },

            checkIfLoggedIn() {
                if(localStorage['user']) {
                    this.loggedIn = true
                }
                else {
                    this.loggedIn = false
                }
            },

            clear () {
                this.$v.$reset()
                this.albumName = ''
                this.albumDate = ''
                this.albumSrc = ''
                this.albumLink = ''
            },
            
        },
        created() {
            this.interval = setInterval(() => this.checkIfLoggedIn(), 500);
        },

        beforeMount() {
            this.getAlbums()
            this.interval = setInterval(() => this.getAlbums(), 10000)        
        }
    }
</script>