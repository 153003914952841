<template>
    <div class="addEvent">
        <section v-if="loggedIn && this.role.includes('ROLE_ADMIN')">
            <v-card class="ma-3">
                <v-card-title class="red darken-4 white--text text-h4">
                    Benutzer
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    <v-data-table
                        :search="search"
                        :items="userList"
                        :headers="userHeaders"
                    >
                        <template v-slot:[`item.actions`]="{ item }"> 
                            <v-btn small color="error" @click="user = item.username; deleteUser(item.id);"><v-icon small>mdi-delete</v-icon></v-btn>
                        </template> 
                    </v-data-table>
                </v-card-text>
            </v-card>

            <v-card class="ma-3">
                <v-card-title class="red darken-4 white--text text-h4">
                    Kontakt
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    Work in progress..
                </v-card-text>

                <v-divider></v-divider>

                <v-card-text>
                    <v-data-table
                        :search="search"
                        :items="contactList"
                        :headers="contactHeaders"
                    >
                        <template v-slot:[`item.actions`]="{ item }"> 
                            <v-btn small color="error" @click="contact = item.name; deleteContact(item.id);"><v-icon small>mdi-delete</v-icon></v-btn>
                        </template> 
                    </v-data-table>
                </v-card-text>
            </v-card>
        </section>

        <section v-if="loggedIn && !this.role.includes('ROLE_ADMIN')" >
            <v-card class="ma-3">
                <v-card-title class="red darken-4 white--text text-h4">
                   Nicht Berechtigt!
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    Du hast nicht die nötigen Berechtigungen!
                </v-card-text>
            </v-card>
        </section>

        <section v-if="!loggedIn" >
            <v-card class="ma-3">
                <v-card-title class="red darken-4 white--text text-h4">
                    You are not logged in!
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    Please login in order to register a new user!
                </v-card-text>
            </v-card>
        </section>

        <v-snackbar 
            v-model="snackbar" 
            :timeout="timeout" 
            multi-line
            right 
            top
            outlined
            light
            text
            :color=errorType
        >
            {{ErrorMessage}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color=errorType
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>       
        </v-snackbar>
    </div>
</template>

<script>
    import authHeader from '@/functions/index.js'
    import { authPrefix, eventPrefix, url} from '@/variables'

    export default {
        computed: {

        },
        data() {
            return{
                loggedIn: false,
                //Snackbar
                snackbar: false,
                ErrorMessage: "",
                errorType: "info",
                timeout: 2000,

                //Lists
                search: "",
                //userList
                userList: [],
                userHeaders: [
                    {text: 'Username', value: 'username', class: 'title font-weight-bold'},
                    {text: 'Rollen', value: 'roles', class: 'title font-weight-bold'},
                    {text: 'Actions', value: "actions", align: 'center', class: 'title font-weight-bold'},
                ],

                //contactList
                contactList: [],
                contactHeaders: [
                    {text: 'Username', value: 'username', class: 'title font-weight-bold'},
                    {text: 'Rollen', value: 'roles', class: 'title font-weight-bold'},
                    {text: 'Actions', value: "actions", align: 'center', class: 'title font-weight-bold'},
                ],

                //Snackbar Info
                user: [],
                contact: [], 
                role: [],

                //Error Handling
                deleteUserErr: false,
                deleteContactErr: false,

                //LocalStorage
                localStorageUser: [],
            }
        },
        methods: {
            checkIfLoggedIn() {
                if(localStorage['user']) {
                    this.loggedIn = true
                    let user = JSON.parse(localStorage.getItem('user'));
                    this.localStorageUser = user
                    this.role = user.roles
                }
                else {
                    this.loggedIn = false
                    this.userList = []
                    this.role = []
                }
            },

//Contacs

            async getContacts() {
                if(localStorage["user"] != null && this.role.includes('ROLE_ADMIN')) {
                    await this.axios.get(url + eventPrefix + "/users", {headers: authHeader()}).then((response) => {
                        this.contactList = response.data
                    })
                }
            },

            async deleteContact(id) {
                // POST request using axios with set headers
                if(window.confirm("Der Kontakt wird unwiederruflich gelöscht! Sind Sie sicher?"))
                {
                    const contact = { id: id};
                    await this.axios.post(url + authPrefix +"/deleteContact", contact, { headers: authHeader() })
                    .catch((err) => {
                        this.ErrorMessage = err.response.data.message
                        this.errorType = "error"
                        this.snackbar = true;
                        this.deleteContactErr = true
                    });
                    if(this.deleteContactErr != true) {
                        this.ErrorMessage = "Kontakt " + this.user.toString() + " entfernt!"
                        this.errorType = "success"
                        this.snackbar = true
                        this.getContacts()
                        this.deleteContactErr = false
                    }
                     
                }
            },

//Users

            async getUsers() {
                if(localStorage["user"] != null && this.role.includes('ROLE_ADMIN')) {
                    await this.axios.get(url + authPrefix + "/users", {headers: authHeader()}).then((response) => {
                        this.userList = response.data
                    })
                }
            },

            async deleteUser(id) {
                // POST request using axios with set headers
                if(this.localStorageUser.id != id) {
                    if(window.confirm("Der Benutzer wird unwiederruflich gelöscht! Sind Sie sicher?"))
                    {
                        const user = { id: id};
                        await this.axios.post(url + authPrefix +"/deleteUser", user, { headers: authHeader() })
                        .catch((err) => {
                            this.ErrorMessage = err.response.data.message
                            this.errorType = "error"
                            this.snackbar = true;
                            this.deleteUserErr = true
                        })
                        if(this.deleteUserErr != true) {
                            this.ErrorMessage = "User " + this.user.toString() + " gelöscht!"
                            this.errorType = "success"
                            this.snackbar = true
                            this.getUsers() 
                            this.deleteUserErr = false
                        }
                                        
                    }
                } else {
                    this.ErrorMessage = "Fehler: Du bist mit diesem Benutzer eingelogged!"
                    this.errorType = "info"
                    this.snackbar = true
                }
            },
        },
        created() {
            this.interval = setInterval(() => this.checkIfLoggedIn(), 1000);
            this.interval = setInterval(() => this.getUsers(), 1000)
        },

        beforeMount() {
            if(localStorage["user"] != null){this.getUsers()}      
        },
    }
</script>